$len: 242.776657104492px;
$time: 1s;

[class*="col"] #loader {
  position: relative;
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) matrix(1, 0, 0, 1, 0, 0) rotate(45deg);
}

#outline {
  stroke-dasharray: $len * 0.01, $len;
  stroke-dashoffset: 0;
  animation: anim $time linear infinite;
  animation-delay: var(--spinner-delay);
}

@keyframes anim {
  12.5% {
    stroke-dasharray: $len * 0.14, $len;
    stroke-dashoffset: -$len * 0.11;
  }

  43.75% {
    stroke-dasharray: $len * 0.35, $len;
    stroke-dashoffset: -$len * 0.35;
  }

  100% {
    stroke-dasharray: $len * 0.01, $len;
    stroke-dashoffset: -$len * 0.99;
  }
}
